<template>
  <v-main class="bg-body-color main">
    <div class="wrapper__auth-new" style="justify-content: flex-start; ">
      <div class="auth__logo" @click="redirectToGeorApp">
        <img src="@/assets/images/logo.svg" alt="Geor, iniciar sesión" />
      </div>
      <div class="auth__box">
        <span style="margin-bottom: 4px; font-size: 18px; font-weight: 700;" >Código de verificación</span>
        <p class="auth__box-p" style="font-size: 14px;">
          Te hemos enviado un SMS con el código a <br />{{ form.prefix }} {{ form.phone }}
        </p>
        <br />
        <ValidationObserver v-slot="{ invalid }" tag="div" ref="formVerifyCode">
          <form @submit.prevent="onSubmit" autocomplete="off">
            <ValidationProvider
              name="código"
              vid="code"
              rules="required|digits:6"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="form.code"
                label="Código"
                :error-messages="errors"
                v-mask="'######'"
                placeholder="Ingrese el código de verificación..."
              ></v-text-field>
            </ValidationProvider>
            <div class="text-center">
              <v-btn
                :disabled="invalid || loading"
                color="primary"
                :loading="loading"
                depressed
                block
                class="v-btn-pad-x-large"
                type="submit"
                style="font-size: 14px; border-radius: 5px !important;"
                ><span class="text-button-new">Validar</span></v-btn
              >
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </v-main>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_URL_WEB,
      form: {
        country_id: null,
        phone: null,
        code: null,
        prefix: null,
        type: "phone",
      },
    };
  },
  mounted() {
    const query = { ...this.$route.query };
    if (!query.country_id || !query.phone || !query.prefix) {
      this.$toast.warning("No se encontró el número de teléfono.");
      this.$router.push({ name: "Register" });
      return;
    }
    this.form.country_id = query.country_id;
    this.form.phone = query.phone;
    this.form.prefix = query.prefix;
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
    }),
  },
  methods: {
    onSubmit() {
      this.$axios
        .post("auth/login/verify", this.form)
        .then((response) => {
          const { data } = response;
          const token = this.$auth.generateToken(data.data.token_type, data.data.access_token);
          this.$auth.saveUser(data.data.user);
          this.$store.dispatch("ON_LOGIN", token);
          this.$router.push({ name: "Dashboard" });
        })
        .catch((error) => this.showErrors(error, "formVerifyCode"));
    },
    redirectToGeorApp() {
      // Redirecciona a la ruta externa
      window.location.href = 'https://geor.app/';
    },
  },
};
</script>
